import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontSizes, Heading } from '@hexure/ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e5e5e5;
  margin-top: 20px;
  table-layout: fixed;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 16px;
  border: 1 solid #ddd;
  font-size: ${FontSizes.DEFAULT};
  max-width: 50%;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ProductDetail = ({ carrier, data, productType }) => {
  const getMessage = (min, max, type) => {
    let message = 'N/A';

    const formatAmount = amount => {
      if (amount == null || isNaN(amount) || typeof amount === 'boolean' || amount === '') {
        return '';
      }
      return Number(amount).toLocaleString();
    };

    if (min == null && max == null) {
      return message;
    }

    if (type === 'premiums') {
      if (min !== undefined && max !== undefined) {
        message = `Min: $${formatAmount(min)} - Max: $${formatAmount(max)}`;
      } else if (min !== undefined) {
        message = `Min: $${formatAmount(min)}`;
      } else if (max !== undefined) {
        message = `Max: $${formatAmount(max)}`;
      }
    } else if (type === 'age') {
      if (min !== undefined && max !== undefined) {
        message = `Min: ${min} - Max: ${max}`;
      } else if (min !== undefined) {
        message = `Min: ${min}`;
      } else if (max !== undefined) {
        message = `Max: ${max}`;
      }
    }

    return message;
  };

  return (
    <Container>
      <Heading type='tertiary'>Product Details</Heading>
      <Table>
        <tbody>
          <TableRow>
            <TableCell />
            <TableCell>{carrier?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>{data?.product_name ? data?.product_name : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Carrier's Rating</TableCell>
            <TableCell>{data?.carrier_rating ? data?.carrier_rating : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>State Approved</TableCell>
            <TableCell>{data?.states_approved?.length > 0 ? data?.states_approved?.join(', ') : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Issue Ages</TableCell>
            <TableCell>{getMessage(data?.issue_min_age, data?.issue_max_age, 'age')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Premium Min/Max</TableCell>
            <TableCell>{getMessage(data?.premium_min ?? 'Not Available', data?.premium_max ?? 'Not Available', 'premiums')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Surrender Charge Period</TableCell>
            <TableCell>{data?.surrender_charge ? `${data?.surrender_charge} Year` : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Surrender Charge Schedule</TableCell>
            <TableCell>{data?.surrender_charge_schedule ? `${data?.surrender_charge_schedule}` : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>FL Eapp</TableCell>
            <TableCell>{data?.fl_eapp ? data?.fl_eapp : 'N/A'}</TableCell>
          </TableRow>
          {productType !== 'Fixed Annuity' ? (
            <>
              <TableRow>
                <TableCell>Riders</TableCell>
                <TableCell>{data?.riders ? data?.riders : 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Bonus</TableCell>
                <TableCell>{data?.bonus ? data?.bonus : 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MVA</TableCell>
                <TableCell>{data?.mva ? data?.mva : 'N/A'}</TableCell>
              </TableRow>
            </>
          ) : null}
          <TableRow>
            <TableCell>Free Withdrawals</TableCell>
            <TableCell>{data?.fee_withdrawals ? `${data?.fee_withdrawals}%` : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Waivers</TableCell>
            <TableCell>{data?.waivers ? data?.waivers : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{productType !== 'Fixed Annuity' ? 'Minimum Guarantee' : 'Guaranteed Minimum Interest Rate'}</TableCell>
            <TableCell>
              {productType === 'Fixed Annuity'
                ? data?.minimum_guaranteed_interest_rate
                  ? `${data?.minimum_guaranteed_interest_rate}%`
                  : 'N/A'
                : data?.minimum_guaranteed_years
                  ? `${data?.minimum_guaranteed_years} years`
                  : 'N/A'}
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </Container>
  );
};

ProductDetail.propTypes = {
  carrier: PropTypes.object,
  data: PropTypes.object
};

export default ProductDetail;
