import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Numeral from 'numeral';
import Icon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiChevronUp, mdiFileDocumentOutline, mdiMagnify, mdiTuneVertical } from '@mdi/js';
import { Button, Copy, Field, Input, Select, Pagination } from '@hexure/ui';

import RangePicker from 'shared/RangePicker';

import { ThemeContext } from 'shared/ThemeContext';

const Top = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-radius: ${props => (props.$rounded ? '8px' : '8px 8px 0px 0px')};
`;

const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
`;

const TopRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 50%;
`;
const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Search = styled.div`
  flex: 1;
`;

const Filters = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterMenu = styled.div`
  position: absolute;
  z-index: 10;
  right: -12px;
  top: 44px;
  width: 460px;
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  border-radius: 0px 0px 8px 8px;
  border-right: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  border-left: 1px solid #f9f9f9;
  background: #fff;
  box-shadow: 0px 12px 30px -15px rgba(0, 0, 0, 0.3);
`;

const Bottom = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
`;

const FilterIcon = styled(Icon)`
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.$disabled ? 0.6 : 1)};
`;

const ResultsBar = ({
  disabled,
  filters,
  resultsCount,
  selectedCount = 0,
  onCompare,
  onFilter,
  visible_quotes,
  setPaginationQuotes,
  setSelectedQuotes,
  selectedQuotes,
  paginationQuotes,
  solveFor
}) => {
  const { color } = useContext(ThemeContext);
  const [showFilters, setShowFilters] = useState(false);
  const [isChecked, setIsChecked] = useState(paginationQuotes?.every(quote => selectedQuotes?.includes(quote.id)));
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const sort_options = [
    { value: 'ambest', label: 'A.M. Best Rating' },
    { value: 'base_rate', label: 'Base Rate' },
    { value: 'carrier', label: 'Carrier Name' },
    { value: 'current_rate', label: 'Current Rate' },
    { value: 'gtd_rate_years', label: 'Guaranteed Rate Years' },
    { value: 'surrender_charge', label: 'Surrender Charge Years' }
  ];
  const perPage = 10;

  if (solveFor === 'Income') {
    sort_options.push({ value: 'monthly_income', label: 'Approximated Income' });
  } else if (solveFor === 'Contribution') {
    sort_options.push({ value: 'premium', label: 'Approximated Contribution' });
  } else if (solveFor === 'Accumulation') {
    sort_options.push({ value: 'accumulation', label: 'Approximated Accumulation' });
  } else if (solveFor === 'GMBIncome') {
    sort_options.push({ value: 'premium', label: 'Approximated Income' });
  }

  const handleRangeChange = e => {
    const range = e.map(value => Numeral(value).value());
    onFilter('filter_range', { target: { value: range } });
  };
  const handleCheckboxChange = () => {
    setIsChecked(prevIsChecked => {
      const newIsChecked = !prevIsChecked;
      setSelectedQuotes(
        newIsChecked
          ? [...selectedQuotes, ...paginationQuotes.map(quote => quote.id).filter(id => !selectedQuotes.includes(id))]
          : selectedQuotes.filter(id => !paginationQuotes.map(quote => quote.id).includes(id))
      );
      setSelectedQuotes(
        newIsChecked
          ? [...selectedQuotes, ...paginationQuotes.map(quote => quote.id).filter(id => !selectedQuotes.includes(id))]
          : selectedQuotes.filter(id => !paginationQuotes.map(quote => quote.id).includes(id))
      );
      return newIsChecked;
    });
  };

  const handlePagination = e => {
    const startIndex = (e - 1) * perPage + 1;
    const endIndex = Math.min(startIndex + perPage - 1, resultsCount);
    setStartIndex(startIndex);
    setEndIndex(endIndex);
    setPaginationQuotes(() => {
      const newPaginationQuotes = visible_quotes.slice(startIndex - 1, endIndex);
      setIsChecked(newPaginationQuotes.every(quote => selectedQuotes.includes(quote.id)));
      return newPaginationQuotes;
    });
    setCurrentPage(e);
    // setSelectedQuotes();
  };

  useEffect(() => {
    setPaginationQuotes(visible_quotes?.slice(0, perPage));
    setStartIndex(visible_quotes.length === 0 ? 0 : 1);
    setEndIndex(Math.min(perPage, resultsCount));
    setIsChecked(false);
  }, [visible_quotes]);

  function getLabelByValue(value) {
    const option = sort_options.find(option => option.value === value);
    return option ? option.label : 'Unknown';
  }

  return (
    <div>
      <Top $rounded={!selectedCount}>
        <TopLeft>
          <React.Fragment>
            <Checkbox onClick={handleCheckboxChange} id={`all-quote-checkbox`}>
              <Icon color={isChecked ? color : '#999'} path={isChecked ? mdiCheckboxMarked : mdiCheckboxBlankOutline} size='28px' />
            </Checkbox>

            {isChecked ? (
              <Bottom>
                <Copy>{selectedQuotes?.length} Selected</Copy>
              </Bottom>
            ) : (
              <Bottom>
                <Copy>({selectedCount} Selected)</Copy>
              </Bottom>
            )}
          </React.Fragment>
          {/*<Copy type='bold'>{resultsCount} Results</Copy>*/}
          <Button disabled={selectedQuotes?.length < 2} icon={mdiFileDocumentOutline} onClick={onCompare} small={true}>
            Create Comparison
          </Button>
        </TopLeft>
        <TopRight>
          <Search>
            <Input icon={mdiMagnify} onChange={onFilter ? onFilter.bind(null, 'search') : () => {}} placeholder='Search by carrier or product name' readOnly={disabled} value={filters.search} />
          </Search>
          <Filters>
            <FilterIcon
              $disabled={disabled || resultsCount < 2 || !paginationQuotes.length}
              color={color}
              onClick={disabled || resultsCount < 2 || !paginationQuotes.length ? null : setShowFilters.bind(null, !showFilters)}
              path={showFilters ? mdiChevronUp : mdiTuneVertical}
              size='24px'
            />
            {showFilters ? (
              <FilterMenu>
                <Field label='Sort By'>
                  <Row>
                    <Select onChange={onFilter.bind(null, 'sort_by')} options={sort_options} value={filters.sort_by} />
                    <Select
                      onChange={onFilter.bind(null, 'sort_dir')}
                      options={[
                        { value: 'asc', label: 'Ascending' },
                        { value: 'desc', label: 'Descending' }
                      ]}
                      value={filters.sort_dir}
                    />
                  </Row>
                </Field>
                <Field label='Filter By'>
                  <Copy margin='8px 0px 0px' type='small'>
                    {getLabelByValue(filters?.sort_by)}
                  </Copy>
                  <RangePicker
                    color={color}
                    max={filters?.filter_high}
                    min={filters?.filter_low}
                    onChange={e => handleRangeChange(e)}
                    rangeValues={filters?.filter_range}
                    renderLabel={value => Numeral(value).format('$0,0')}
                    step={1}
                    values={filters?.filter_values}
                  />
                </Field>
              </FilterMenu>
            ) : null}
          </Filters>
        </TopRight>
      </Top>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', alignItems: 'center' }}>
        <Copy type='bold'>{`${startIndex} - ${endIndex} of ${resultsCount}`}</Copy>
        <Pagination currentPage={currentPage} onClick={e => handlePagination(e)} pageCount={Math.ceil(resultsCount / perPage)} />
      </div>
    </div>
  );
};

ResultsBar.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object,
  onCompare: PropTypes.func,
  onFilter: PropTypes.func,
  paginationQuotes: PropTypes.array,
  resultsCount: PropTypes.number,
  selectedCount: PropTypes.number,
  selectedQuotes: PropTypes.array,
  setPaginationQuotes: PropTypes.func,
  setSelectedQuotes: PropTypes.func,
  solveFor: PropTypes.string,
  visible_quotes: PropTypes.array
};

export default ResultsBar;
